import React from 'react'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PencilIcon } from '@heroicons/react/outline'
import Message from './Message';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const bunmailApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR);


const EmailMessages = () => {
  const queryClient = useQueryClient();
  const params = useParams()
  const { data: draftIds, isLoading, isError } = useQuery({
    queryFn: bunmailApi.getFn({
      endpoint: 'index_drafts_being_composed',
    }),
    queryKey: ['drafts_being_composed'],
  });


  return (
    <div className='fixed right-24 bottom-0 z-50 '>
      <div className="flex space-x-5 items-end">
        {draftIds && draftIds.slice(0,2).map(({id}) => (
          <div key={id}>
            <Message id={id} />
         </div>
        ))}
    
          {/* <button onClick={() => createDraft()} className="btn-gray-rounded  mb-6 p-2"><PencilIcon className='h-5 w-5' /></button> */}
      </div>
    </div>
  )
}

export default EmailMessages