import React from 'react';
import CsvExportButton from '../../CSV-Export-Button';
import { useQuery } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../api';
const smartTableApi = new ApiRequest(API_ROUTES.SMART_TABLE, API_AUTH_TYPES.ADVISOR);

const filterKeys = (obj, keysToFilter) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (!keysToFilter.includes(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
};
const keysToFilter = ['household_id', 'row_id','stripe','advisor_id','advisor']; // Replace with actual keys you want to filter out


const CSVDownload = ({ tableId, selectedViewId }) => {
    const {
        data,
        isLoading
    } = useQuery({
        queryKey: ["smart-table-export", { tableId, selectedViewId }],
        queryFn: () =>
            smartTableApi.get({
                endpoint: "table",
                params: [tableId, selectedViewId, 0, 'true'],
            }),
    });

    if (isLoading || !data) return <></>;

    // Concatenate the rows from all the pages
    const rows = data?.rows;

    // Keys to filter out from each object

    // Filter keys from each row
    const filteredRows = rows.map(row => filterKeys(row, keysToFilter));

    // Access metadata from the first page
    const metadata = data?.metadata;

    return (
        <div>
            <CsvExportButton
                data={filteredRows}
                label='Download View CSV'
                filename={`${metadata.table_name} - ${metadata.view_name}`}
            />
        </div>
    );
};

export default CSVDownload;
