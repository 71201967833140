import React, { useState } from 'react'
import { ToolTipHoverContainer, ToolTipRight } from '../../../../../components/ToolTips'
import { MdScheduleSend } from 'react-icons/md'
import BlankModal from '../../../../../components/modals/BlankModal'
import ReactDatePicker from '../../../../../components/ReactDatePicker'
import { CalendarIcon } from '@heroicons/react/solid'
import { useMutation, useQueryClient } from 'react-query'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api'
import SmallLoadingSpinner from '../../../../../components/loading/SmallLoadingSpinner'

const bunmailApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR)
const ScheduledSend = ({ id }) => {
    const [isOpen, setIsOpen] = useState(false)
    const queryClient = useQueryClient()
    const [date, setDate] = useState(new Date())
    const scheduleSendMutation = useMutation(() => bunmailApi.patch({ endpoint: 'schedule_send', params: [id], body: { scheduled_send_at: date } })
        , {
            onSuccess: () => {
                setIsOpen(false)
                queryClient.setQueryData(['drafts_being_composed'], (old) => (old.filter((draft) => draft.id!== id)))
        }
    }
    )
    return (
        <>
            <BlankModal open={isOpen} setOpen={setIsOpen}>
                <div className="h-96 flex flex-col justify-between">
                    <div className="flex justify-between">
                        <p className="text-2xl text-gray-800 text-left">
                            Pick date & time
                        </p>
                        <ReactDatePicker
                            className='input-primary'
                            selected={date}
                            icon={CalendarIcon}
                            open={true}
                            onChange={setDate} showTimeSelect={true} />





                    </div>
                    <div className="  flex items-center justify-end space-x-2">
                        <button
                            onClick={() => setIsOpen(false) && setDate(new Date())}
                            className='btn-gray-outline-rounded btn-xl'>
                            Cancel
                        </button>
                        <button>
                            <button
                                onClick={() => scheduleSendMutation.mutate()}
                                className='btn-primary-rounded btn-xl'>
                                {
                                    scheduleSendMutation.isLoading ? <SmallLoadingSpinner textColor='text-white'/> : 'Schedule Send'
                               }
                            </button>
                        </button>
                    </div>
                </div>
            </BlankModal>
            <ToolTipHoverContainer>
                <ToolTipRight>Schedule Send</ToolTipRight>
                <button
                    onClick={() => setIsOpen(true)}
                    className='btn-gray-clear-rounded'>
                    <MdScheduleSend className='h-6 w-6 text-primary-500' />
                </button>
            </ToolTipHoverContainer>
        </>
    )
}

export default ScheduledSend