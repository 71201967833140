import React, { useState, useEffect, useRef } from 'react';

const HoverEditComponent = ({ defaultComponent, editComponent, onSave = () => { }, editable }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const wrapperRef = useRef(null);
    const hoverCheckInterval = useRef(null);

    useEffect(() => {
        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                if (isEditing) {
                    onSave();
                }
                setIsEditing(false);
            }
        };

        const node = wrapperRef.current;
        if (node) {
            node.addEventListener('mouseenter', handleMouseEnter);
            node.addEventListener('mouseleave', handleMouseLeave);
        }

        document.addEventListener('mousedown', handleClickOutside);

        // Check if the mouse is still over the component at regular intervals
        hoverCheckInterval.current = setInterval(() => {
            if (node && !node.matches(':hover')) {
                setIsHovered(false);
            }
        }, 100); // Check every 100ms

        return () => {
            if (node) {
                node.removeEventListener('mouseenter', handleMouseEnter);
                node.removeEventListener('mouseleave', handleMouseLeave);
            }
            document.removeEventListener('mousedown', handleClickOutside);
            clearInterval(hoverCheckInterval.current);
        };
    }, [isEditing, onSave]);

    return (
        <>
            {editable ? <div
                ref={wrapperRef}
                onClick={() => setIsEditing(true)}
            >
                {isEditing ? editComponent : isHovered ? editComponent : defaultComponent}
            </div> :
                    defaultComponent
            }

        </>

    );
};

export default HoverEditComponent;
