import { Fragment, useRef, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { getReq } from "../../apis/internalApi";
import { classNames } from "../../helpers/classNames";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { API_ROUTES } from "../../api";
import useNav from "../../hooks/useNav";
import useKeyboardShortcut from "../../hooks/useKeyShortcut";

/**
 * Fetches households from the server using the provided access token.
 * @param {string} accessToken - The access token for authentication.
 * @returns {Promise} A promise that resolves to the fetched household data.
 */
const fetchHouseholds = async (accessToken) => {
  const response = await getReq(
    API_ROUTES.HOUSEHOLDS,
    "index_all_households_auth_advisor",
    accessToken
  );
  return response.data;
};

/**
 * Component for the command palette used to search for households.
 * @param {Object} props - The component's props.
 * @param {function} props.setOpen - Function to open/close the command palette.
 * @param {boolean} props.open - Whether the command palette is open.
 */
export default function CommandPaletteHouseholdSearch({ setOpen, open }) {

  useKeyboardShortcut(() => setOpen(true), "Space", true);


  const nav = useNav();
  const [query, setQuery] = useState("");
  const [households, setHouseholds] = useState([]);
  const [selectedHouseholdName, setSelectedHouseholdName] = useState('');
  const [selectedUrl, setSelectedUrl] = useState(households[0]);

  const accessToken = useSelector((state) => state.user.accessToken);

  // Fetch household data when the component mounts
  const createItemsArray = useCallback(async () => {
    const fetchedItems = await fetchHouseholds(accessToken);
    setHouseholds(fetchedItems);
    setSelectedUrl(`/crm/households/${fetchedItems[0].household_id}`);
  }, [accessToken]);

  useEffect(() => {
    createItemsArray();
  }, [createItemsArray]);

  // Filter households based on the search query
  const filteredHouseholds =
    query === ""
      ? []
      : households.filter((household) => {
        return household.name.toLowerCase().includes(query.toLowerCase());
      });

  
  // Keep track of how many times this effect has run
  const runCount = useRef(0);

  useEffect(() => {
    if (runCount.current >= 2) {
      nav(selectedUrl);
    }
    runCount.current += 1;
  }, [selectedUrl]);

  // Handle the selection of a household from the dropdown
  const handleChange = (household) => {
    setSelectedHouseholdName(household.name);
    setSelectedUrl(`/crm/households/${household.household_id}`);
  };

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mt-16 md:mt-0 mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={handleChange} value={selectedHouseholdName}>
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}

                  />
                </div>

                {filteredHouseholds.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {filteredHouseholds.map((household) => (
                     
                        <Combobox.Option
                          key={household.household_id}
                          value={household}
                          onClick={() => { nav(`/crm/households/${household.household_id}`) }}
                          className={({ active }) =>
                            classNames(
                              "cursor-default select-none px-4 py-2  w-full",
                              active && "bg-blue-600 text-white"
                            )
                          }
                        >
                          {household.name}
                        </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== "" && filteredHouseholds.length === 0 && (
                  <p className="p-4 text-sm text-gray-500">No people found.</p>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
