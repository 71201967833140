import React from 'react'
import FinancialStoryAccess from '../Settings/components/FinancialStoryAccess';
import YouvestAccess from '../Settings/components/YouvestAccess';


/**
 * Component for rendering access settings for FinancialStory and Youvest.
 * It wraps the individual access components for each service in a layout container.
 *
 * This component is useful for providing a centralized location where users can manage
 * access permissions or settings related to FinancialStory and Youvest platforms.
 *
 * @returns {JSX.Element} A component that renders the access settings for FinancialStory and Youvest.
 */
const Access = () => {
  return (
    <div className='space-y-6'>
      <FinancialStoryAccess />
      <YouvestAccess />
    </div>
  )
}

export default Access