import { GrDocumentCsv } from "react-icons/gr";
import React from 'react';

function CsvExportButton({ filename, data,label }) {
    function exportToCsv(filename, data) {
        // Create CSV headers from the object keys of the first row
        const csvHeaders = Object.keys(data[0]).join(',');

        // Convert each data row to a CSV string and join with new lines
        const csvRows = data.map(row =>
            Object.values(row).map(value =>
                {
                if (!value) return '""'; // Handle null/undefined values
               return `"${value.toString().replace(/"/g, '""')}"`// Handle quotes in values
            }
            ).join(',')
        ).join('\r\n');

        // Combine headers and rows with a new line in between
        const csvString = `${csvHeaders}\r\n${csvRows}`;
        // Create a Blob with CSV data
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

        // Create a link and trigger the download
        const link = document.createElement('a');
        if (link.download !== undefined) { // Feature check for download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }


    return <button className="btn-gray-outline btn-md space-x-2" onClick={() => exportToCsv(filename, data)}>
        <p>{ label}</p>
        <GrDocumentCsv className='h-5 w-5 ' /></button>;
}

export default CsvExportButton;
