import React, { useEffect, useState } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { RiPercentLine } from "react-icons/ri";
import { useMutation, useQueryClient } from 'react-query';
import ApiRequest from '../../../../../api/ApiRequest';
import { API_AUTH_TYPES, API_ROUTES } from '../../../../../api/apiConstants';
import SmallLoadingSpinner from '../../../../../components/loading/SmallLoadingSpinner';
import BlankModal from '../../../../../components/modals/BlankModal';

/**
 * Component to edit details of a cashflow item within a modal.
 * It allows updating of item name, type, amount, period, and location.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.item - Initial cashflow item details to edit.
 * @param {string} props.id - ID of the cashflow item being edited.
 * @param {boolean} props.open - Controls the visibility of the modal.
 * @param {Function} props.setOpen - Function to update the modal's open state.
 * @param {boolean} props.isClientPortal - Indicates if the operation is performed in a client portal.
 * @returns {JSX.Element} A modal component for editing cashflow item details.
 */
const EditCashflow = ({ item, id, open, setOpen, isClientPortal }) => {
    const [name, setName] = useState(item.name);
    const [type, setType] = useState(item.type);
    const [amount, setAmount] = useState(item.amount);
    const [period, setPeriod] = useState(item.period);
    const [annualContribution, setAnnualContribution] = useState(item.annual_contribution);
    const [transferType, setTransferType] = useState(item.transfer_type);
    const [location, setLocation] = useState(item.location);

    const authType = isClientPortal ? API_AUTH_TYPES.USER : API_AUTH_TYPES.ADVISOR;
    const cashflowApi = new ApiRequest(API_ROUTES.CASHFLOW, authType);

    const queryClient = useQueryClient();
    const updateItem = useMutation(
        (data) => cashflowApi.patch({
            endpoint: 'update_item',
            body: data
        }),
        {
            onSuccess: () => {
                // Invalidate the queries related to cashflow to refresh the data.
                queryClient.invalidateQueries(id);
                queryClient.invalidateQueries(`cashflow-ids-${item.household_id}`);
                queryClient.invalidateQueries(isClientPortal ? `total-annual-contribution-mine` : `total-annual-contribution-${item.household_id}`)

                setOpen(false); // Close the modal upon successful update.
            }
        }
    );

    useEffect(() => {
        if (type === 'Maxing Out') {
            setPeriod("Yearly");
        }
    }, [type]);
    
    

    const handleSubmitUpdate = (e) => {
        e.preventDefault();
        updateItem.mutate({
            item_id: id,
            name,
            amount,
            type,
            period,
            location,
            annual_contribution: annualContribution,
            transfer_type: transferType
        });
    };

    return (
        <BlankModal open={open} setOpen={setOpen}>
            <h3 className="text-lg text-left mb-2">Edit Savings Item</h3>
            <form className="grid sm:grid-cols-2 gap-5 text-left" onSubmit={handleSubmitUpdate}>
                <div>
                    <label htmlFor="name" className="label-primary">Name</label>
                    <input
                        type="text"
                        id='name'
                        className='input-primary'
                        value={name}
                        required
                        placeholder='Emergency Fund'
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="location" className="label-primary">Bank/Custodian</label>
                    <input
                        type="text"
                        id='location'
                        className='input-primary'
                        value={location}
                        placeholder='Ally Bank'
                        onChange={(e) => setLocation(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="type" className="label-primary">Type</label>
                    <select
                        id='type'
                        className='input-primary'
                        value={type}
                        required
                        onChange={(e) => setType(e.target.value)}
                    >
                        <option hidden value=''>Select Type</option>
                        <option value='Maxing Out'>Maxing Out</option>
                        <option value='Dollar Amount'>Dollar Amount</option>
                        <option value='Percent'>Percent</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="transfer-type" className="label-primary">Transfer Type</label>
                    <select
                        id='transfer-type'
                        className='input-primary'
                        value={transferType}
                        required
                        onChange={(e) => setTransferType(e.target.value)}
                    >
                        <option hidden value=''>Select Transfer Type</option>
                        <option value='Manual'>Manual</option>
                        <option value='Automatic'>Automatic</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="period" className="label-primary">Frequency</label>
                    {type === "Maxing Out" ?
                        <p className='text-base text-left'>Yearly</p> :
                        <select
                            id='period'
                            className='input-primary'
                            value={period}
                            onChange={(e) => setPeriod(e.target.value)}
                        >
                            <option hidden value=''>Select Frequency</option>
                            <option value='Weekly'>Weekly</option>
                            <option value='Monthly'>Monthly</option>
                            <option value='Quarterly'>Quarterly</option>
                            <option value='Yearly'>Yearly</option>
                        </select>}
                </div>
                <div>
                <>
                        <label htmlFor="amount" className="label-primary">Amount</label>
                        <div className="flex items-center">
                            {type === 'Dollar Amount' && <BsCurrencyDollar className='h-5 w-5 mr-2' />}
                            {type === "Maxing Out" ?
                                <p className='text-base'>Maxing Out</p> :
                                <input
                                    type="number"
                                    id='amount'
                                    className='input-primary'
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />}
                            {type === 'Percent' && <RiPercentLine className='h-5 w-5 ml-2' />}
                        </div>
                    
                    </>
                </div>
                <div>
                    <label htmlFor="annual_contribution" className="label-primary">Annual Amount</label>
                    <div className="flex items-center">
                        <BsCurrencyDollar className='h-5 w-5 mr-2' />
                        <input
                            type="number"
                            id='annual_contribution'
                            className='input-primary'
                            value={annualContribution}
                            required
                            placeholder='$50,000'
                            onChange={(e) => setAnnualContribution(e.target.value)}
                        />
                    </div>
                </div>
               
                

                <div className='sm:col-span-2 flex justify-center'>
                    <button
                        className="btn-lg btn-primary"
                        type="submit"
                        disabled={updateItem.isLoading}
                    >
                        {updateItem.isLoading ? <SmallLoadingSpinner textColor='text-white' /> : 'Submit Edits'}
                    </button>
                </div>
            </form>
        </BlankModal>
    );
};

export default EditCashflow;
