import React from "react";
import { useState } from "react";
import { patchReq } from "../../../../apis/internalApi";
import { dateFormaterWithTime } from "../../../../helpers/formatters";
import SmartTableEditCell from "./../SmartTable___EditCell";
/**
 * Formats the given date according to the specified format.
 * @param {string} date - The date to format.
 * @param {boolean} showTime - Whether to include the time in the formatted date.
 * @return {string|null} - The formatted date, or null if the input date is null.
 */
const formatDate = (date, showTime) => {
  // Return null if the input date is null
  if (date === null || date === undefined) return null;

  // Use the input date string directly to create a new Date object
  const formattedDate = new Date(date);

  // Check if the date object is valid
  if (isNaN(formattedDate)) return "Invalid Date";

  // Format the date according to the specified format
  return showTime
    ? dateFormaterWithTime(formattedDate)
    : formattedDate.toLocaleDateString();
};




const DateComponent = ({
  initDate,
  showTime = false,
  editable,
  table,
  colId,
  accessToken,
  rowId,
  editId
}) => {
  // Destructure state variables and setter functions
  const [date, setDate] = useState(formatDate(initDate, showTime) || null);
  const [showEdit, setShowEdit] = useState(false);

  // Function to submit changes to the table

  const submitChanges = async () => {
    try {
      // Make the patch request to update the table
      const results = await patchReq(table, "smart_table_update", accessToken, {
        value: date ? date : null,
        row_id: editId,
        col_id: colId,
      });
    } catch (error) {
      // Handle error
    }
  };

  // Render the component
  return (
    <div className="smart-table-cell ">
      {editable ? (
        // If the cell is editable, render the editable component
        <SmartTableEditCell
          date={date}
          
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          handleSubmitEdit={submitChanges}
          defaultComponent={
            <time className="">{date ? formatDate(date, showTime) : null}</time>
          }
          editComponent={
            <div className="relative">
              <input
                type={showTime ? "datetime-local" : "date"}
                name={`date_${colId}`}
                rows={5}
                id={`date_${colId}`}
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="shadow-sm absolute -top-5 border-2 focus:ring-0 focus:border-blue-500 border-blue-500 block w-full sm:text-sm rounded-md"
              />
            </div>
          }
        />
      ) : (
        // If the cell is not editable, render the default component
        <time className=""> {date ? formatDate(date, showTime) : null}</time>
      )}
    </div>
  );
};

export default DateComponent;
