import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { useAuth } from "../../context/auth-context";
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  GlobeIcon,
  MenuAlt2Icon,
  ChartPieIcon,
  AcademicCapIcon,
  HomeIcon,
  XIcon,
  UserIcon,
  NewspaperIcon,
  BookOpenIcon,
  HeartIcon,
  ChatAltIcon,
} from "@heroicons/react/outline";
import useNav from "../../hooks/useNav";
import { authenticateAdvisorAdmin } from "../../helpers/jwtAuth";
import { postReq } from "./../../apis/internalApi";
import { API } from "./../../constants/index";
import { TbBread } from "react-icons/tb";
import { useQuery } from "react-query";
import ApiRequest from "../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "../../api/apiConstants";
import DefaultLoading from './../loading/DefaultLoading';
import Notifications from "../Notifications";
import { classNames } from "../misc/styling";



// images
const abundoLogo =
  "https://res.cloudinary.com/abundo-wealth-assets/image/upload/v1658176803/Abundo%20Wealth%20Assets/components/logos/Abundo_logo_mtnbxi_zg4tju.webp";
//end images

export default function SideNavBar({ title, children, bgColor }) {
  //hooks
  const nav = useNav();
  const { logout } = useAuth();
  //state
  const user = useSelector((state) => state.user);
  const [isAdvisor, setIsAdvisor] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const usersApi = new ApiRequest(API_ROUTES.USERS, API_AUTH_TYPES.USER);
  const { data:linkData,isLoading } = useQuery({
    queryFn: usersApi.getFn({ endpoint: "calendly_link" }),
    queryKey: "calendlyLink",
  });
  const [calendlyLink, setCalendlyLink] = useState('');
  useEffect(() => {
    if (linkData) {
      setCalendlyLink(linkData);
    }
  }, [linkData,isLoading]);

  //data
  const handleManageSubscription = async () => {
    try {
      const response = await postReq(
        API.STRIPE,
        "create_customer_portal_session",
        accessToken,
        {}
      );
      if (response.status === 200) {
        window.location = response.data.redirect;
      }
    } catch (error) {}
  };

  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current:
        window.location.href.includes("/dashboard") &&
        !window.location.href.includes("/admin") &&
        !window.location.href.includes("/advisor"),
    },
    // {
    //   name: "YouVest",
    //   href: "/youvest/Dashboard",
    //   target: "",
    //   rel: "",
    //   icon: ChartBarIcon,
    //   current: window.location.href.includes("/youvest"),
    // },
    {
      name: "Financial Story",
      href: "/financial-story",
      target: "",
      rel: "",
      icon: BookOpenIcon,
      current: window.location.href.includes("/financial-story"),
    },

    {
      name: "Learning Lab",
      href: "/learning_lab",
      target: "",
      rel: "",

      icon: AcademicCapIcon,
      current: window.location.href.includes("/learning_lab"),
    },

   
  ];

  const specialNav = [

    {
      name: "",
      href: "/abundo-love-corner",
      icon: HeartIcon,
      current: false,
    },
  ]

  const externalLinks = [

    {
      name: "Schedule Meeting",
      href: calendlyLink,
      target: "_blank",
      rel: "noopener noreferrer",
      icon: CalendarIcon,
      current: false,
    },
    {
      name: "RightCapital",
      target: "_blank",
      rel: "noopener noreferrer",

      href: "https://app.rightcapital.com/account/login",
      icon: ChartPieIcon,
      current: false,
    },
    {
      name: "Abundo's Blog",
      icon: ChatAltIcon,
      target: "_blank",
      rel: "noopener noreferrer",
      href: "/blog",
    },

    {
      name: "Newsletter",
      href: "https://us20.campaign-archive.com/home/?u=016756adcf51a6649b4e96ba5&id=108ece5b0e",
      target: "_blank",
      rel: "noopener noreferrer",
      icon: NewspaperIcon,
      current: false,
    },
 
  ];


  const advisor = [
    {
      name: "Bun",
      href: "/crm/dashboard",
      icon: TbBread,
      current: window.location.href.includes("/advisor"),
    },
  ];

  const userNavigation = [
    {
      name: "Sign out",
      func: () => {
        try {
          nav("/");
          setTimeout(() => {
            logout(); // If there's an error, log the user out.
          }, 1000);        } catch (error) {}
      },
    },
    {
      name: "Manage Subscription",
      func: handleManageSubscription,
    },
  ];


  const legal = [
    {
      name: "Terms of Use",
      href: "/legal/terms_of_use",
      target: "_blank",
      rel: "noopener noreferrer",
      current: false,
    },
    {
      name: "Additional Terms",
      href: "/legal/additional_terms",
      target: "_blank",
      rel: "noopener noreferrer",
      current: false,
    },
    {
      name: "Notice & Disclaimer",
      href: "/legal/notice_and_disclamer",
      target: "_blank",
      rel: "noopener noreferrer",
      current: false,
    },
    {
      name: "Privacy Policy",
      href: "/legal/privacy_policy",
      target: "_blank",
      rel: "noopener noreferrer",
      current: false,
    }, {
      name: "Form CRS",
      href: 'https://drive.google.com/file/d/1_AmOl_tqhslSjoIq56BhVe74wG2R-lCT/view?usp=sharing',
      target: "_blank",
      rel: "noopener noreferrer",
      current: false,
    },
    {
      name: "Form ADV",
      href: 'https://drive.google.com/file/d/15DE8c-3wVcPnWM39ODn9T-er2MUWRofk/view?usp=sharing',
      target: "_blank",
      rel: "noopener noreferrer",
      current: false,
    },
 
  ];

  const accessToken = useSelector((state) => state.user.accessToken);
  //functions

  const householdsApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.USER);

  const authenticateAdminAdvisor = useCallback(async () => {
    try {
      const auth = await authenticateAdvisorAdmin(accessToken);
      const res = await householdsApi.get({ endpoint: "has_youvest_access" })
      setIsAdvisor(auth.is_advisor);
    } catch (error) {
      console.error(error)
      nav("/");
      setTimeout(() => {
        logout(); // If there's an error, log the user out.
      }, 1000);
    }
  }, [accessToken]);



  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const authenticate = async () => {
      try {
        const res = await authenticateAdminAdvisor();
      } catch (error) {}
    };
    authenticate();
  }, [accessToken, authenticateAdminAdvisor]);

  //*``
  if (isLoading) return <DefaultLoading />
  return (
    <>
  
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <a
                  href="/dashboard"
                  className=" flex-shrink-0 flex items-center px-4"
                >
                  <img className="h-8 w-auto" src={abundoLogo} alt="abundo" />{" "}
                </a>
                <div className="mt-5 flex-1 overflow-y-auto ">
                  <nav className="px-2 space-y-1">
                    {navigation && navigation.map((item) => (
                      <button
                        key={item.name}
                        className="w-full"
                        
                      >
                        <a
                          key={item.name}
                          href={item.href}
                          rel={item.rel}
                          target={item.target}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </button>
                    ))}

                    {externalLinks.map((item) => (
                      <button
                        key={item.name}
                        className="w-full"

                      >
                        <a
                          key={item.name}
                          href={item.href}
                          rel={item.rel}
                          target={item.target}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </button>
                    ))}
                    {specialNav && specialNav.map((item) => (
                      <button
                        key={item.name}
                        className="w-full"

                      >
                        <a
                          key={item.name}
                          href={item.href}
                          rel={item.rel}
                          target={item.target}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </button>
                    ))}

                    {isAdvisor &&
                      advisor.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          rel={item.rel}
                          target={item.target}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                  </nav>
                  <nav className="mt-5 border-t-2 px-2 pb-4 flex space-x-2 items-center justify-center pt-2">
                    <div className="flex-1 pb-4 space-y-1  mx-2">
                      {" "}
                      {legal.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          rel={item.rel}
                          target={item.target}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          {item.name}
                        </a>
                      ))}{" "}
                    </div>{" "}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:w-52 lg:flex-col lg:fixed lg:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow border-r  pt-5 bg-white overflow-y-auto">
            <a
              className="flex items-center justify-center flex-shrink-0 px-4"
              href="/dashboard"
            >
              <img className="h-14 w-auto" src={abundoLogo} alt="abundo" />
            </a>
            <div className="mt-5 flex-grow flex flex-col">
              <div>
                {" "}
                <nav className="flex-1 pb-4 space-y-1  mx-2">
                  {" "}
                  {navigation && navigation.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => nav(item.href)}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center w-full px-2 py-2 text-sm font-medium rounded-md"
                      )}


                    >
                   
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                    </button>
                  ))}{" "}
                  {externalLinks.map((item) => (

                    <a
                      key={item.name}
                      href={item.href}
                      rel={item.rel}
                      target={item.target}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}{" "}
                  {specialNav && specialNav.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => nav(item.href)}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center w-full px-2 py-2 text-sm font-medium rounded-md"
                      )}


                    >

                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </button>
                  ))}{" "}
              
                  {isAdvisor &&
                    advisor.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        rel={item.rel}
                        target={item.target}
                        className={classNames(
                          item.current
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                </nav>{" "}
              </div>
              <nav className="mt-auto border-t-2 px-2 pb-4 flex space-x-2 items-center justify-center pt-2">
                <div className="flex-1 pb-4 space-y-1  mx-2">
                  {" "}
                  {legal.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      rel={item.rel}
                      target={item.target}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      {item.name}
                    </a>
                  ))}{" "}
                </div>{" "}
              </nav>
            </div>
          </div>
        </div>
        {/* End Static sidebar for desktop */}
        <div className=" lg:pl-52 flex flex-col flex-1">
          <div className="sticky lg:relative top-0 z-50 flex-shrink-0 flex h-12 bg-c-orange-abundo shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 bg-white text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-c-orange-abundo lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-end">
              <div className=" flex items-center lg:ml-6 space-x-1">
       <Notifications />
                <button
                  className="w-full flex items-center"
                
                >
                  <a
                    type="button"
                    href={calendlyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="  p-1 rounded-full hover:bg-white transition duration-200 "
                  >
                    <span className="sr-only">View notifications</span>

                    <CalendarIcon
                      className="h-6 w-6 text-white hover:text-gray-400 transition duration-200"
                      aria-hidden="true"
                    />
                  </a>
                </button>
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div className="">
                    <Menu.Button className=" p-1 rounded-full hover:bg-white transition duration-300 ">
                      <span className="sr-only">Open user menu</span>
                      <UserIcon
                        className="h-6 w-6 text-white hover:text-gray-400 transition duration-300"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                              )}
                              onClick={() => {
                                item.func();
                              }}
                            >
                              {item.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main
            className={`flex-1 justify-center min-h-screen relative px-5 sm:px-6 xl:px-10  ${
              bgColor ? bgColor : " bg-c-midnight-50"
            }  pt-7`}
          >
            <div className="max-w-full overflow-x-hidden pb-8 rounded-lg">
              {" "}
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
