import React from "react";
import { currencyFormatter, timeFromDate, timeToDate } from "../../../helpers/formatters";
import { useParams } from "react-router-dom";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../api";
import { useQuery } from "react-query";
import { ToolTipHoverContainer, ToolTipLeft } from "../../../components/ToolTips";
import { InformationCircleIcon } from "@heroicons/react/outline";

const HHStats = () => {
  // Extracting the household_id parameter from the URL
  const { household_id: householdId } = useParams();


  const householdApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR);

  // Fetching the HH stats data using React Query
  const { data, isLoading } = useQuery({
    queryFn: householdApi.getFn({ endpoint: `hh_stats`, params: [householdId] }),
    queryKey: `hh-stats-${householdId}`,
  });

  return (
    <div className="w-full -my-2">
      {/* Displaying a loading message if the data is still loading */}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="grid grid-cols-2 gap-5">
              {/* Rendering each stat item */}
              {data.stats.map((item) => (
                <li key={item.name}>
                 
                  <div className="flex items-center space-x-1">
                    <p className="text-sm text-gray-500">
                      {item.name}

                    </p>
                  {
                    item.description && (
                      <ToolTipHoverContainer>
                        <InformationCircleIcon className="h-3 w-3 text-gray-500" />
                        <ToolTipLeft>{item.description}</ToolTipLeft>
                      </ToolTipHoverContainer>
                    )

                  }
                  </div>
                  {item.stat ? (
                    <p
                      className="font-semibold"
                      aria-hidden="true"
                    >
                      {(item.type === "number" || item.type === "text") && item.stat}{" "}
                      {item.type === "date_past"
                        ? timeFromDate(item.stat) || "N/A"
                        : ""}{" "}
                      {item.type === "date_future"
                        ? timeToDate(item.stat) || "N/A"
                        : ""}{" "}
                      {item.type === "trueorfalse" ? (item.stat ? "Yes" : "No") : ""}
                      {item.type === 'currency' &&  `${currencyFormatter.format(item.stat)}`}
                    </p>
                  ) : (
                    <p className="font-semibold">
                      {item.type === "trueorfalse" && "No"}
                      {item.type === "number" && 0}{" "}
                        {item.type === "date_past" && "N/A"}
                        {item.type === "date_future" && "N/A"}

                    </p>
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      )}
    </div>
  );
};

export default HHStats;
