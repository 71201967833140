import { ChevronDownIcon, CogIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import ClientStatus from "./components/ClientStatus";
import DeleteHousehold from "./components/DeleteHousehold";
import Subscription from "./components/Subscription";
import FinancialStoryAccess from './components/FinancialStoryAccess';
import Advisor from './components/Advisor';
import { classNames } from "../../../../helpers/classNames";
import FadeIn from "../../../../components/transitions/FadeIn";
import { BasicPanel } from "../../../../components/panels";

const Settings = ({
  householdMeta
}) => {
  const [showValues, setShowValues] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  return (
    <div className="w-full max-w-lg">
    <BasicPanel>
    <div className="  flex flex-col ">
      <div className="space-y-1">
        {" "}



      
          <div className="space-y-3 py-4">
            <Advisor />
            <FinancialStoryAccess />
            <Subscription householdMeta={householdMeta} />
            <ClientStatus householdMeta={householdMeta} />
            <DeleteHousehold householdId={householdMeta.household_id} />
          </div>



      </div>
      </div>
    </BasicPanel></div>

  );
};

export default Settings;
