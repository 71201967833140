import React, { useContext, useState } from 'react'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../api';
import { useMemo } from 'react';
const YouvestContext = React.createContext();

export function useYouvestContext() {
    return useContext(YouvestContext);
}
const YouvestProvider = ({ children }) => {

    const [showChooseInstitution, setShowChooseInstitution] = useState(false);
    const [showManuallyAddAccount, setShowManuallyAddAccount] = useState(false);

   
    return (
        <YouvestContext.Provider value={{
            showChooseInstitution,
            setShowChooseInstitution,
            showManuallyAddAccount,
            setShowManuallyAddAccount,
        }}>
            {children}
        </YouvestContext.Provider>
    )

}

export default YouvestProvider