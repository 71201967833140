/**
 * A React component that displays a tooltip on hover.
 * 
 * @param {React.ReactNode} children - The content to display in the tooltip
 */
export function ToolTipBottom({ children }) {
    if (!children) return null
    return (
        <p className={`bottom-0  translate-y-full text-sm  mt-2 w-max max-w-[250px] z-40
         transition duration-200 ease-in p-2 left-0 hidden group-hover:block absolute bg-gray-800 rounded-lg text-white`}>
       {children}
        </p>
    )
}


/**
 * A React component that displays a tooltip on hover.
 * 
 * @param {React.ReactNode} children - The content to display in the tooltip
 */
export function ToolTipTop({ children }) {
    if (!children) return null
    return (
        <p className={`top-0  -translate-y-full text-sm  mt-2 w-max max-w-[250px] z-40
         transition duration-200 ease-in p-2 left-0 hidden group-hover:block absolute bg-gray-800 rounded-lg text-white`}>
            {children}
        </p>
    )
}

/**
 * A React component that displays a tooltip on hover.
 * 
 * @param {React.ReactNode} children - The content to display in the tooltip
    */
export function ToolTipLeft({ children }) {
    if (!children) return null
    return (
        <p className={`left-0  -translate-x-full text-sm  mt-2 w-max max-w-[250px] z-40
         transition duration-200 ease-in p-2 top-011 hidden group-hover:block absolute bg-gray-800 rounded-lg text-white`}>
            {children}
        </p>
    )

}


/**
 * A React component that displays a tooltip on hover.
 * 
 * @param {React.ReactNode} children - The content to display in the tooltip
 */
export function ToolTipRight({ children }) {
    if (!children) return null
    return (
        <p className={`right-0  translate-x-full text-sm  mt-2 w-max max-w-[250px] z-40
         transition duration-200 ease-in p-2 top-0 hidden group-hover:block absolute bg-gray-800 rounded-lg text-white`}>
            {children}
        </p>
    )
}

/**
 * A React component that wraps another component and displays a tooltip on hover.
 * 
 * @param {React.ReactNode} children - The child component to wrap
 */
export function ToolTipHoverContainer({ children }) {
    return (
        <div className="relative group">
            {children}
        </div>
    )
}